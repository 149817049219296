import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorPage from 'containers/ErrorPage';
import Spinner from 'components/Spinner/Spinner';
import { withRouter } from 'react-router';
import UIActions from 'actions/UIActions';
import {
  primaryApplicantHasSignedPrivacyPolicy,
  primaryApplicantHasSeenContext,
} from 'selectors/clientSelectors';
import { getApplicationHasSignedPrivacyPolicy } from 'selectors/applicationSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';
import { routesRedirectionValidation } from 'lib/redirectHelper';
import * as UISelectors from 'selectors/UISelectors';

export class PageWrapper extends Component {
  static propTypes = {
    fullPageSpinnerLoading: PropTypes.bool,
    pageError: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.node,
    clearPageError: PropTypes.func,
    primaryApplicantHasSignedPrivacyPolicy: PropTypes.boolean,
    primaryApplicantHasSeenContext: PropTypes.boolean,
    applicationHasSignedPrivacyPolicy: PropTypes.booleasn,
    pollingStatus: PropTypes.object,
    isDataCollectionSuccess: PropTypes.bool,
    hasDataCollectionFailed: PropTypes.bool,
    isDataCollectionPending: PropTypes.bool,
    logoutStatus: PropTypes.bool,
    hasCreditCheckFailed: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    // Clear page error on error page leave
    const {
      pageError,
      location,
      clearPageError,
      applicationHasSignedPrivacyPolicy,
      primaryApplicantHasSignedPrivacyPolicy,
      primaryApplicantHasSeenContext,
      history,
      fetchingUserInfo,
      pollingStatus,
      isDataCollectionSuccess,
      hasDataCollectionFailed,
      isDataCollectionPending,
      logoutStatus,
      hasCreditCheckFailed,
    } = this.props;

    routesRedirectionValidation({
      primaryApplicantHasSignedPrivacyPolicy,
      applicationHasSignedPrivacyPolicy,
      history,
      fetchingUserInfo,
      pollingStatus,
      primaryApplicantHasSeenContext,
      isDataCollectionSuccess,
      hasDataCollectionFailed,
      isDataCollectionPending,
      logoutStatus,
      hasCreditCheckFailed,
    });

    if (pageError && location !== prevProps.location) {
      clearPageError();
    }
  }

  render() {
    const { fullPageSpinnerLoading, pageError, children } = this.props;
    if (pageError) {
      return <ErrorPage error={pageError} />;
    }
    if (fullPageSpinnerLoading) {
      return <Spinner loading />;
    }
    return children;
  }
}

const mapStateToProps = (state) => {
  const { fullPageSpinnerLoading, pageError, logoutStatus } = state.UISettings;
  return {
    fullPageSpinnerLoading,
    pageError,
    logoutStatus,
    primaryApplicantHasSignedPrivacyPolicy: primaryApplicantHasSignedPrivacyPolicy(
      state,
    ),
    primaryApplicantHasSeenContext: primaryApplicantHasSeenContext(state),
    applicationHasSignedPrivacyPolicy: getApplicationHasSignedPrivacyPolicy(
      state,
    ),
    fetchingUserInfo: UISelectors.fetchingUserInfo(state),
    pollingStatus: dataCollectionSelectors.pollingStatus(state),
    isDataCollectionSuccess: dataCollectionSelectors.isDataCollectionSuccess(
      state,
    ),
    hasDataCollectionFailed: dataCollectionSelectors.hasDataCollectionFailed(
      state,
    ),
    isDataCollectionPending: dataCollectionSelectors.isDataCollectionPending(
      state,
    ),
    hasCreditCheckFailed: dataCollectionSelectors.hasCreditCheckFailed(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearPageError: UIActions.clearPageError,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageWrapper),
);
