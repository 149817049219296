/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/display-name */
/* eslint-disable-next-line react/react-in-jsx-scope  */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import {
  DASHBOARD_BASE_PATH,
  APPLY_BASE_PATH,
  PRIVACY_POLICY_PATH,
  DATA_COLLECTION_BANK_STATEMENT_PATH,
  DATA_COLLECTION_ID_VERIFICATION_PATH,
  DATA_COLLECTION_LANDING_PATH,
  DATA_COLLECTION_CREDIT_CHECK_PATH,
} from 'shared/constants/paths';

import LocalStorageProxy from 'lib/localStorageProxy';
import { featureFlags } from 'lib/rollout';
import { DATA_COLLECTION_PROCESS } from 'constants/dataCollection';

export const pathValidations = ({
  component: Component,
  fetchingUserInfo,
  loadingGoalLoanApp,
  primaryApplicant,
  primaryContact,
  application,
  path,
  isDataCollectionPending,
  isIdVerificationPending,
  isAutomaticIdVerificationSuccess,
  isCreditCheckPending,
  isBankStatementsPending,
  hasCreditCheckFailed,
  hasDataCollectionFailed,
  selectedProcesses,
  isFetchingLoanApplication,
  hasOldInfo,
  isDataCollectionEnabled,
  ...rest
}) => {
  const isPrivacyPolicyEnabled = featureFlags.oneTouchPrivacy.isEnabled();
  const redirectToPrivacy =
    isPrivacyPolicyEnabled &&
    !LocalStorageProxy.loginAsLoanApplicationId &&
    !fetchingUserInfo &&
    !!primaryApplicant &&
    !(
      application?.hasSignedPrivacyPolicy ||
      primaryApplicant?.hasSignedPrivacyPolicy
    );

  if (redirectToPrivacy) {
    return <Redirect to={PRIVACY_POLICY_PATH} />;
  }
  const isDataCollectionFFEnabled = featureFlags[
    'consumer.data-collection'
  ].isEnabled();
  const applicationLocked = application?.isLocked;
  const isBrokerLogin =
    !!LocalStorageProxy.loginAsClientId ||
    !!LocalStorageProxy.loginAsLoanApplicationId;

  const redirectToDataCollection =
    !applicationLocked &&
    isDataCollectionFFEnabled &&
    !fetchingUserInfo &&
    !!primaryApplicant &&
    !isBrokerLogin &&
    !redirectToPrivacy;

  if (redirectToDataCollection) {
    if (isDataCollectionPending) {
      if (isIdVerificationPending) {
        return <Redirect to={DATA_COLLECTION_LANDING_PATH} />;
      }

      if (isCreditCheckPending) {
        return <Redirect to={DATA_COLLECTION_ID_VERIFICATION_PATH} />;
      }

      if (isBankStatementsPending) {
        if (selectedProcesses[0] === DATA_COLLECTION_PROCESS.Illion) {
          return <Redirect to={DATA_COLLECTION_LANDING_PATH} />;
        } else {
          return <Redirect to={DATA_COLLECTION_BANK_STATEMENT_PATH} />;
        }
      }
    } else if (
      isAutomaticIdVerificationSuccess &&
      hasDataCollectionFailed &&
      hasCreditCheckFailed &&
      !(
        primaryApplicant.hasCreditCheckRetried ||
        application?.hasCreditCheckRetried
      )
    ) {
      return <Redirect to={DATA_COLLECTION_CREDIT_CHECK_PATH} />;
    }
  }

  const { id } = application;
  if (
    id &&
    window.location.hash.includes(DASHBOARD_BASE_PATH) &&
    !applicationLocked &&
    !fetchingUserInfo &&
    !!primaryApplicant &&
    isFetchingLoanApplication
  ) {
    return <Redirect to={`${APPLY_BASE_PATH}/${id}`} />;
  }

  return <SecureRoute {...rest} component={Component} />;
};
