import { featureFlags } from 'lib/rollout';
import {
  PRIVACY_POLICY_PATH,
  LOGIN_CAPTURE_PATH,
  DATA_COLLECTION_STATUS_POLLING_PATH,
  DASHBOARD_BASE_PATH,
  CONTEXT_PAGE_PATH,
} from 'shared/constants/paths';
import LocalStorageProxy from 'lib/localStorageProxy';
import { isBrokerLogin } from 'lib/utils/common';

export function redirectUrl(location) {
  if (location.hash) {
    const gaRegex = /^\/?(&_ga.*)/;
    const queryMatch = location.pathname.match(gaRegex);
    if (queryMatch) {
      const query = queryMatch[1];
      return `/${location.hash}${query}`;
    }
  }

  return false;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function routesRedirectionValidation(props) {
  const {
    primaryApplicantHasSignedPrivacyPolicy,
    applicationHasSignedPrivacyPolicy,
    history,
    fetchingUserInfo,
    pollingStatus,
    primaryApplicantHasSeenContext,
    isDataCollectionSuccess,
    hasDataCollectionFailed,
    isDataCollectionPending,
    logoutStatus,
    hasCreditCheckFailed,
  } = props;

  const isPrivacyPolicyEnabled = featureFlags.oneTouchPrivacy.isEnabled();
  const isNotLoginPath = history.location.pathname !== LOGIN_CAPTURE_PATH;
  const commonStatus =
    !LocalStorageProxy.loginAsLoanApplicationId &&
    !fetchingUserInfo &&
    !logoutStatus;
  const redirectToPrivacy =
    isPrivacyPolicyEnabled &&
    (!applicationHasSignedPrivacyPolicy ||
      !primaryApplicantHasSignedPrivacyPolicy);
  const isNotPrivacyPath = history.location.pathname !== PRIVACY_POLICY_PATH;
  const isNotContextPath = history.location.pathname !== CONTEXT_PAGE_PATH;
  const isDataCollectionPath = history.location.pathname.includes(
    'data-collection',
  );

  const isPrivacyNotSigned =
    isNotLoginPath && isNotPrivacyPath && redirectToPrivacy && commonStatus;

  if (isPrivacyNotSigned) {
    history.replace(PRIVACY_POLICY_PATH);
  }

  const isCollectionStatusCompleteAndContextNotSigned =
    (isDataCollectionSuccess || hasDataCollectionFailed) &&
    isNotContextPath &&
    isNotPrivacyPath &&
    isNotLoginPath &&
    !hasCreditCheckFailed &&
    !primaryApplicantHasSeenContext;

  const isSmartDataProgressDoneAndNotContextSigned =
    (isDataCollectionSuccess || hasDataCollectionFailed) &&
    isDataCollectionPath &&
    isNotContextPath &&
    !hasCreditCheckFailed &&
    !primaryApplicantHasSeenContext;
  /*
    If the smart data processing is complete or there is an error, and the context has not yet been signed, 
    and the user wants to go back to the data-collection path, it will redirect back to context page
  */
  if (
    isSmartDataProgressDoneAndNotContextSigned ||
    isCollectionStatusCompleteAndContextNotSigned
  ) {
    history.replace(CONTEXT_PAGE_PATH);
  }

  /*
    if user trying to skip data collection or 
    go directly in context page and the status is 
    begun and pending it will redirect back previous data-collection path
  */
  const isContextNotSignedAndisDataCollectionPending =
    isNotLoginPath &&
    isNotPrivacyPath &&
    !isDataCollectionPath &&
    isDataCollectionPending &&
    !primaryApplicantHasSeenContext &&
    commonStatus;

  if (isContextNotSignedAndisDataCollectionPending) {
    history.goBack();
  }

  const isDataPollingInprogress =
    pollingStatus.status &&
    pollingStatus.percentage !== 100 &&
    history.location.pathname !== DATA_COLLECTION_STATUS_POLLING_PATH;
  /*
    If the user tries to go back to the data-collection statement 
    while data polling is in progress, 
    they will be redirected back to the polling page.
  */
  if (isDataPollingInprogress) {
    history.replace(DATA_COLLECTION_STATUS_POLLING_PATH);
  }

  const isSmartDataProgressDoneAndContextSigned =
    (isDataCollectionSuccess ||
      (hasDataCollectionFailed && !hasCreditCheckFailed)) &&
    history.location.pathname.includes('data-collection') &&
    primaryApplicantHasSeenContext;
  /*
    If the user tries to go to the context page, but the context has already 
    been signed and the data collection is 
    either complete or has an error 
    (hasCreditCheckFailed to be false or else it will redirect data-collection-verify-address),
    hey will be redirected back to the dashboard.
  */

  const isBrokerLoginDataCollectionPath = isBrokerLogin && isDataCollectionPath;
  /*
    isBrokerLogin and trying to go in data-collection path
  */
  if (
    isSmartDataProgressDoneAndContextSigned ||
    isBrokerLoginDataCollectionPath
  ) {
    history.replace(DASHBOARD_BASE_PATH);
  }
}
