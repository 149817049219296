/* eslint-disable no-unused-vars */
/* eslint-disable unicorn/explicit-length-check */
import {
  takeEvery,
  takeLatest,
  all,
  put,
  select,
  call,
  take,
  fork,
} from 'redux-saga/effects';
import {
  READ_LOAN_APPLICATION,
  UPDATE_LOAN_APPLICATION,
  SEND_LOAN_APPLICATION_FOR_REVIEW,
  REQUEST_SECURITIES,
  REQUEST_LOAN_APPLICATION_STATUS,
  SET_NEW_WORKING_APPLICATION,
  REQUEST_GOAL_SETTER_QUESTIONS_AND_ANSWERS,
  CONFIRM_LOAN_APPLICATION,
} from 'actions/loanApplicationActionTypes';
import structureActions, { SAVE_STRUCTURE } from 'actions/structureActions';
import { SET_NEW_CLIENT } from 'actions/clientActionTypes';

import {
  ADDRESS_TYPE_CURRENT,
  ADDRESS_OWNERSHIP_TYPE_RENTING,
} from 'shared/constants/myCRMTypes/address';

import loanApplicationActions from 'actions/loanApplicationActions';
import contactActions from 'actions/contactActions';
import clientActions from 'actions/clientActions';
import assetActions from 'actions/assetActions';
import liabilityActions from 'actions/liabilityActions';
import incomeActions from 'actions/incomeActions';
import expenseActions from 'actions/expenseActions';
import propertiesActions from 'actions/applyPropertyActions';
import UIActions from 'actions/UIActions';
import fundingActions from 'actions/fundingActions';
import addressActions from 'actions/addressActions';
import employmentActions from 'actions/employmentActions';
import householdActions from 'actions/householdActions';
import scenarioActions from 'actions/scenarioActions';
import incomeTypeActions from 'actions/incomeTypeActions';
import depositTypeActions from 'actions/depositTypeActions';
import documentActions from 'actions/documentActions';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as advisorSelectors from 'selectors/advisorSelectors';
import * as UISelectors from 'selectors/UISelectors';
import { totalLoanRequired } from 'selectors/fundingSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as completionSelectors from 'selectors/completionSelectors';

import { fetchDocuments, getClientFileInvite } from 'sagas/documentSagas';
import {
  requestLoanAppInfoQuestions,
  requestLoanAppInfoAnswers,
} from 'sagas/goal/goalLoanAppInfoSagas';
import { fetchAccreditedLenders } from 'sagas/lenderSagas';

import { getContactIds } from 'lib/utils/formUtils';
import { featureFlags } from 'lib/rollout';

import * as SpinnerNames from 'constants/spinnerNames';
import {
  PREFERENCES,
  LONG_TERM_GOALS,
  RETIREMENT,
  TEAM,
} from 'shared/constants/goalLoanAppInfo';
import locale from 'config/locale';

import * as _ from 'sagas/loanApplicationSagas';

import {
  putLoanApplication,
  getFundingsFromLoanApplication,
  getStructuresFromLoanApplication,
  putStructureForLoanApplication,
  postStructureForLoanApplication,
  getProperties,
  getSecuritiesFromLoanApplication,
  getForeseeableChangesFromLoanApplication,
  putForeseeableChangesForLoanApplication,
} from 'services/loanApplicationApi';

import { getDetail as getProductDetail } from 'services/productsApi';
import {
  getContactAssets,
  getContactLiabilities,
  getContactExpenses,
  getContactIncomes,
  getContactClients,
  getContactEmployments,
  getContactAddress,
} from 'services/contactApi';
import { getClient, getLoanApplicationStatus } from 'services/clientApi';
import { getCompany } from 'services/companyApi';
import { getIncomeTypes as getAllIncomeTypes } from 'services/incomeApi';
import { getDepositTypes as getAllDepositTypes } from 'services/fundingsApi';
import { getDocumentTypes as getAllDocumentTypes } from 'services/documentApi';
import { getTitleOptions as getAllTitleOptions } from '../services/clientApi';

import { newNotOwnResidencyProperty } from 'reducers/propertyReducer';

import { monitorAsyncRequest, monitorSpinnerRequest } from 'lib/sagaHelpers.js';
import { getPropertyOwnershipById } from 'lib/propertyHelper';

import { SINGLE_HOUSEHOLD } from 'shared/constants/options';

export function* readAndLoadStructures({ payload: { loanApplicationId } }) {
  // Loan structure / facilities / selected product whatever you want to call this:
  const isShared = yield select(applicationSelectors.isSharedApplication);

  if (!isShared) {
    const structures = yield call(
      getStructuresFromLoanApplication,
      loanApplicationId,
    );

    // eslint-disable-next-line no-unused-vars
    for (const structure of structures) {
      try {
        structure.productDetail = yield call(
          getProductDetail,
          structure.productId,
          structure.loanAmount,
          structure.loanTerm,
          structure.repaymentType,
        );
      } catch (error) {
        structure.productDetail = {};
      }
    }

    if (structures.length) {
      yield put(structureActions.setNewStructures(structures));
    } else {
      const loanAmount = yield select(totalLoanRequired);
      if (loanAmount > 0) {
        yield put(structureActions.updateWorkingStructure({ loanAmount }));
      }
    }
  }
}

export function* setLoanApplication(
  loanApplication,
  securities,
  foreseeableChanges = [],
) {
  if (
    loanApplication.metadata &&
    loanApplication.metadata.hasHousehold === false
  ) {
    yield put(householdActions.setHouseholdShape(SINGLE_HOUSEHOLD));
  }

  yield put(
    loanApplicationActions.mergeLoanApplication({
      id: loanApplication.id,
      contacts: getContactIds(loanApplication.applicants),
      securities,
      foreseeableChanges,
    }),
  );
  yield put(loanApplicationActions.loadApplication(loanApplication.id));
}

export function* setAddresses(contactIds) {
  let contactAddresses = [];
  for (const contactId of contactIds) {
    const addresses = yield call(getContactAddress, contactId);
    contactAddresses = contactAddresses.concat(addresses);
  }
  for (const address of contactAddresses) {
    if (
      parseInt(address.typeId, 10) === ADDRESS_TYPE_CURRENT.id &&
      !address.ownershipTypeId
    ) {
      address.ownershipTypeId = ADDRESS_OWNERSHIP_TYPE_RENTING.id; // default
    }
    yield put(addressActions.setNewAddress(address));
  }
}

export function* setPrimaryContact(
  primaryApplicantId,
  primaryContactId,
  loanAppId,
) {
  const allPrimaryClients = yield call(
    getContactClients,
    primaryContactId,
    loanAppId,
  );
  let primaryContact = {};
  for (const primaryClient of allPrimaryClients) {
    const primaryApplicant = primaryApplicantId === primaryClient.id;
    yield put(
      clientActions.setNewOrMergeClient({
        ...primaryClient,
        primaryApplicant,
        isPartnerClient:
          !primaryApplicant && primaryContactId === primaryClient.contactId,
      }),
    );
    if (primaryApplicantId === primaryClient.id) {
      primaryContact = primaryClient;
    }
  }
  const advisor = yield select(advisorSelectors.advisor);
  yield put(
    contactActions.setNewOrMergeContact({
      ...primaryContact,
      id: primaryContactId,
      brokerId: advisor.familyId,
      isPrimaryContact: true,
    }),
  );
  yield call(_.setAddresses, [primaryContactId]);
}

export function* setCoapplicants(
  applicants,
  primaryApplicantId,
  primaryContactId,
) {
  const coapplicants = applicants.filter(
    (c) => c.clientId !== primaryApplicantId,
  );
  let contactIds = [];
  for (const coapplicant of coapplicants) {
    const { companyId, clientId, contactId } = coapplicant;
    if (primaryContactId !== contactId) {
      contactIds = contactIds.concat(contactId);
    }
    if (companyId) {
      const company = yield call(getCompany, companyId, contactId);
      yield put(
        contactActions.setNewOrMergeContact({
          ...company,
          companyId,
          clientId,
          isCompany: true,
          id: company.contactId,
          isPrimaryContact: false,
        }),
      );
    } else {
      const client = yield call(getClient, clientId);
      yield put(
        clientActions.setNewOrMergeClient({
          ...client,
          id: clientId,
          contactId,
          primaryApplicant: false,
          isPartnerClient: primaryContactId === contactId,
        }),
      );
    }
  }
  yield call(_.setAddresses, contactIds);
}

export function* fetchProfileData(params) {
  const { fetcher, id, otherArgs = [], setter, shouldOverwriteIsOld } = params;
  try {
    const data = yield call(fetcher, id, ...otherArgs);
    if (shouldOverwriteIsOld) {
      data.forEach((s) => {
        s.isOldData = false;
      });
    }
    yield put(setter(data));
  } catch (error) {
    console.warn(error);
  }
}

export function* setRentalResidencyProperty(data, primaryApplicantId) {
  const { realEstateAssets, notOwnProperties } = data;
  const ownResidenceProperty =
    realEstateAssets.find((p) => p.residence) ||
    notOwnProperties.find((p) => p.residence);
  const primaryCurrentAddress = (yield select(
    addressSelectors.clientCurrentAddress,
  ))(primaryApplicantId);
  if (primaryCurrentAddress && !ownResidenceProperty) {
    const ownership = getPropertyOwnershipById(
      primaryCurrentAddress.ownershipTypeId,
    );
    yield put(
      propertiesActions.setNewProperty({
        ...newNotOwnResidencyProperty(ownership),
        clientId: primaryApplicantId,
      }),
    );
  }
}

export function* setProfileData(
  loanApplication,
  primaryContactId,
  primaryApplicantId,
) {
  yield fork(_.readAndLoadStructures, {
    payload: { loanApplicationId: loanApplication.id },
  });
  yield fork(
    _.setCoapplicants,
    loanApplication.applicants,
    primaryApplicantId,
    primaryContactId,
  );
  yield fork(fetchDocuments);
  yield fork(getClientFileInvite, {
    payload: { clientId: primaryApplicantId },
  });

  const sections = {
    fundings: {
      fetcher: getFundingsFromLoanApplication,
      id: loanApplication.id,
      setter: fundingActions.setNewFundings,
    },
    liabilities: {
      fetcher: getContactLiabilities,
      id: primaryContactId,
      setter: liabilityActions.setNewLiabilities,
      otherArgs: [loanApplication.id],
    },
    incomes: {
      fetcher: getContactIncomes,
      id: primaryContactId,
      setter: incomeActions.setNewIncomes,
    },
    expenses: {
      fetcher: getContactExpenses,
      id: primaryContactId,
      otherArgs: [featureFlags.livingExpenseV2.isEnabled()],
      setter: expenseActions.setNewExpenses,
    },
    employments: {
      fetcher: getContactEmployments,
      id: primaryContactId,
      setter: employmentActions.setNewEmployments,
    },
  };

  const shouldOverwriteIsOld = false;
  for (const key of Object.keys(sections)) {
    yield fork(_.fetchProfileData, { ...sections[key], shouldOverwriteIsOld });
  }

  try {
    const { assets, realEstateAssets } = yield call(
      getContactAssets,
      primaryContactId,
    );
    const notOwnProperties = yield call(getProperties, loanApplication.id);
    if (shouldOverwriteIsOld) {
      realEstateAssets.forEach((s) => {
        s.isOldData = false;
      });
    }
    yield put(assetActions.setNewAssets(assets));
    yield put(
      propertiesActions.setNewProperties(
        notOwnProperties.concat(realEstateAssets),
      ),
    );

    yield call(
      _.setRentalResidencyProperty,
      { realEstateAssets, notOwnProperties },
      primaryApplicantId,
    );
  } catch (error) {
    console.warn(error);
  }
}

export function* getSecuritiesAndForseeableChanges(loanApplicationId) {
  try {
    const [securities, foreseeableChanges] = yield Promise.all([
      getSecuritiesFromLoanApplication(loanApplicationId),
      getForeseeableChangesFromLoanApplication(loanApplicationId),
    ]);
    return { securities, foreseeableChanges };
  } catch (error) {
    console.warn(error);
    return {};
  }
}

export function* getIncomeTypes() {
  const incomeTypes = yield call(getAllIncomeTypes, locale.countryCode);
  yield put(incomeTypeActions.setIncomeTypes(incomeTypes));
  yield call(getAllDepositTypes);
}

export function* getDepositTypes() {
  const depositTypes = yield call(getAllDepositTypes);
  yield put(depositTypeActions.setDepositTypes(depositTypes));
}

export function* getDocumentTypes() {
  const documentTypes = yield call(getAllDocumentTypes);
  yield put(documentActions.setDocumentTypes(documentTypes));
}

export function* getTitleOptions() {
  const titleOptions = yield call(getAllTitleOptions);
  yield put(clientActions.setTitleOptions(titleOptions));
}

export function* requestGoalSetterQuestionsAndAnswers({
  payload: { loanAppId, familyId },
}) {
  const sections = [PREFERENCES, LONG_TERM_GOALS, RETIREMENT, TEAM];
  yield fork(requestLoanAppInfoQuestions, { payload: { familyId, sections } });
  yield fork(requestLoanAppInfoAnswers, { payload: { loanAppId, sections } });
}

export function* readLoanApplication({ payload } = {}) {
  const applicationIsRead = yield select(UISelectors.applicationIsRead);
  if (applicationIsRead) {
    return;
  }
  const spinnerName = SpinnerNames.READ_LOAN_APPLICATION;
  yield put(UIActions.pushSpinner(spinnerName));

  try {
    yield fork(_.getIncomeTypes);
    yield fork(_.getDepositTypes);
    yield fork(_.getDocumentTypes);
    yield fork(_.getTitleOptions);

    let loanApplication = yield select(applicationSelectors.workingApplication);
    if (loanApplication.id === undefined) {
      yield take(SET_NEW_WORKING_APPLICATION);
      loanApplication = yield select(applicationSelectors.workingApplication);
    }
    const { securities, foreseeableChanges } = yield call(
      _.getSecuritiesAndForseeableChanges,
      loanApplication.id,
    );
    yield call(
      _.setLoanApplication,
      loanApplication,
      securities,
      foreseeableChanges,
    );

    let primaryApplicantId = yield select(
      clientSelectors.getPrimaryApplicantId,
    );
    if (primaryApplicantId === undefined) {
      yield take(SET_NEW_CLIENT);
      primaryApplicantId = yield select(clientSelectors.getPrimaryApplicantId);
    }
    const primary = loanApplication.applicants.find(
      (applicant) => applicant.clientId === primaryApplicantId,
    );
    const primaryContactId = primary.contactId;
    yield call(
      _.setPrimaryContact,
      primaryApplicantId,
      primaryContactId,
      loanApplication.id,
    );
    if (featureFlags.lendersByAccreditation.isEnabled()) {
      yield fork(fetchAccreditedLenders);
    }
    if (featureFlags.goalSetterFactFindMerge.isEnabled()) {
      yield call(_.requestGoalSetterQuestionsAndAnswers, {
        payload: { loanAppId: loanApplication.id, familyId: primaryContactId },
      });
    }

    if (payload && payload.partialBackgroundLoad) {
      yield put(UIActions.popSpinner(spinnerName));
    }

    yield call(
      _.setProfileData,
      loanApplication,
      primaryContactId,
      primaryApplicantId,
    );

    yield put(UIActions.setApplicationIsRead());
    yield put(UIActions.popSpinner(spinnerName));
  } catch (error) {
    yield put(UIActions.popSpinner(spinnerName));
    throw error;
  }
}

export function* updateForeseeableChanges(applicationId, foreseeableChanges) {
  for (const foreseeableChange of foreseeableChanges) {
    const isPrimaryOrPartnerClient = yield select(
      clientSelectors.isPrimaryOrPartnerClient,
    );
    if (isPrimaryOrPartnerClient(foreseeableChange.clientId)) {
      yield putForeseeableChangesForLoanApplication({
        loanApplicationId: applicationId,
        ...foreseeableChange,
      });
    }
  }
}

// TODO: handle failing later
// Ordinarily we would like myCRM to return the updated entity in the response,
// but for now we'll just persist the payload (working application) straight through
// to entities.
export function* updateLoanApplication({ payload }) {
  const {
    foreseeableChanges,
    hasSignedPrivacyPolicy,
    hasCreditCheckRetried,
    hasSeenContext,
    currentClientId,
    ...rest
  } = payload;

  const currentApplication = yield select(
    applicationSelectors.workingApplication,
  );

  const applicationId = currentApplication.id;
  const updateApplication = currentApplication.applicants.map((applicant) => {
    if (applicant.clientId === currentClientId && hasSeenContext) {
      return { ...applicant, hasSeenContext };
    }

    return applicant;
  });

  yield call(putLoanApplication, {
    ...currentApplication,
    ...rest,
    applicants: updateApplication,
    id: applicationId,
    hasSignedPrivacyPolicy,
    hasCreditCheckRetried,
    hasSeenContext,
    currentClientId,
  });

  if (foreseeableChanges) {
    yield call(updateForeseeableChanges, applicationId, foreseeableChanges);
  }

  if (
    payload.metadata?.hasHousehold === false &&
    currentApplication.metadata?.hasHousehold !== false
  ) {
    yield put(householdActions.setHouseholdShape(SINGLE_HOUSEHOLD));
  }

  const updateLoadApp = {
    hasSignedPrivacyPolicy,
    hasCreditCheckRetried,
    hasSeenContext,
    ...currentApplication,
    ...payload,
    applicants: updateApplication,
  };

  yield put(loanApplicationActions.setLoanApplication(updateLoadApp));

  if (hasSeenContext || hasSignedPrivacyPolicy) {
    yield put(
      clientActions.setClientStatusFromApplication({
        currentClientId,
        hasSeenContext:
          hasSeenContext !== undefined
            ? hasSeenContext
            : currentApplication.hasSeenContext,
        hasSignedPrivacyPolicy:
          hasSignedPrivacyPolicy !== undefined
            ? hasSignedPrivacyPolicy
            : currentApplication.hasSignedPrivacyPolicy,
      }),
    );
  }

  yield put(loanApplicationActions.loadApplication(applicationId));
}

export function* confirmLoanApplication({ payload: { application } }) {
  yield call(updateLoanApplication, { payload: application });
}

export function* sendLoanApplicationForReview() {
  const application = yield select(applicationSelectors.workingApplication);
  const currentFamilyId = yield select(
    clientSelectors.primaryApplicantContactId,
  );
  const currentClientId = yield select(clientSelectors.getPrimaryApplicantId);
  const percentageCompleted = yield select(
    completionSelectors.percentageCompleted,
  );
  const completedSections = yield select(completionSelectors.completedSections);

  yield call(putLoanApplication, {
    ...application,
    status: 1,
    currentFamilyId,
    currentClientId,
  });
  yield put(
    scenarioActions.trackSubmittedForReview({
      appId: application.id,
      familyId: currentFamilyId,
      clientId: currentClientId,
      completionPercent: percentageCompleted,
      completedSections,
    }),
  );
}

// TODO refactor this please. why do we have shouldsaveBla?
export function* saveStructureForLoanApplication({
  payload: { structure, nextPath, shouldSaveToMyCRM = true },
}) {
  const spinnerName = SpinnerNames.SAVE_STRUCTURE_FOR_LOAN_APPLICATION;
  const isShared = yield select(applicationSelectors.isSharedApplication);

  if (nextPath) {
    yield put(UIActions.goToPathWithAnimation(nextPath));
  }

  if (!isShared) {
    yield put(structureActions.updateWorkingStructure(structure));
    if (shouldSaveToMyCRM && structure.loanApplicationId) {
      yield put(UIActions.pushSpinner(spinnerName));
      if (structure.id) {
        // TODO: Handle ownership properly
        yield call(putStructureForLoanApplication, structure);
      } else {
        const clientIds = yield select(
          applicationSelectors.getApplicantClientIds,
        );
        yield call(postStructureForLoanApplication, {
          ...structure,
          clientIds,
        });
      }
      // Fetch and update loan structure
      yield call(_.readAndLoadStructures, {
        payload: {
          loanApplicationId: structure.loanApplicationId,
        },
      });
      yield put(UIActions.popSpinner(spinnerName));
    }
  }
}

export function* requestSecurities({ payload }) {
  const securities = yield call(getSecuritiesFromLoanApplication, payload);

  yield put(loanApplicationActions.setSecurities(securities));
}

export function* requestStatus() {
  const primaryClientId = yield select(clientSelectors.getPrimaryApplicantId);
  const { status } = yield call(getLoanApplicationStatus, primaryClientId);

  yield put(loanApplicationActions.setLoanApplicationStatus(status));
}

export default function* loanApplicationSagas() {
  yield all([
    monitorAsyncRequest(takeLatest, READ_LOAN_APPLICATION, readLoanApplication),
    monitorAsyncRequest(
      takeEvery,
      UPDATE_LOAN_APPLICATION,
      updateLoanApplication,
    ),
    monitorAsyncRequest(
      takeEvery,
      SEND_LOAN_APPLICATION_FOR_REVIEW,
      sendLoanApplicationForReview,
    ),
    monitorAsyncRequest(
      takeEvery,
      SAVE_STRUCTURE,
      saveStructureForLoanApplication,
    ),
    monitorAsyncRequest(takeEvery, REQUEST_SECURITIES, requestSecurities),
    monitorAsyncRequest(
      takeEvery,
      REQUEST_GOAL_SETTER_QUESTIONS_AND_ANSWERS,
      requestGoalSetterQuestionsAndAnswers,
    ),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_LOAN_APPLICATION_STATUS,
      requestStatus,
    ),
    monitorAsyncRequest(
      takeEvery,
      CONFIRM_LOAN_APPLICATION,
      confirmLoanApplication,
    ),
  ]);
}
