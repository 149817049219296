import { getAccessToken } from 'lib/okta';

import authenticate from './authenticate';
import LocalStorageProxy from '../lib/localStorageProxy';

import {
  getLoanApplicationsForClientDirect,
  postClientEventDirect,
  postEmailVerificationTokenDirect,
  postOnlineFactFindDirect,
  postPasswordRecoveryDirect,
  postVerifyEmailDirect,
  putClientMobileDirect,
  setEmailVerifiedDirect,
} from './clientApi';
import { generateUuid, getCorrectApplication } from '../../shared/lib/utils';
import {
  getLoanApplication,
  postStructureForLoanApplicationDirect,
} from './loanApplicationApi';
import {
  buildClientData,
  createLoanApplication,
  createScenario,
  setClientActivated,
  signup,
} from '../lib/myCrmConverter';
import { structureBuilderForMyCRM } from '../../shared/lib/builders/mycrm/structure';

// port of serverless handler: users/users.signUp
export const signUp = (scenario) => {
  const generatedId = generateUuid();
  return signup(scenario || {}).then((data) => ({
    clientId: data.id,
    familyId: data.family_id,
    consumerUuid: generatedId,
  }));
};

// port of serverless handler: users/users.activateShared
export const activateShared = async (clientId, oktaAuth) => {
  await getAccessToken(oktaAuth);

  return setClientActivated(clientId).then(() => {
    return setEmailVerifiedDirect(clientId);
  });
};

// port of serverless handler: users/users.activate
export const activate = async (params, oktaAuth) => {
  await getAccessToken(oktaAuth);

  const { scenario, structure, analyticsClientId, clientId, familyId } = params;
  const saveStructure = !!(structure && structure.productId);

  if (!clientId) {
    return Promise.reject(new Error('ClientId not found'));
  }

  return createLoanApplication(clientId, scenario, analyticsClientId).then(
    ({ id: loanApplicationId }) => {
      const promises = [
        createScenario(clientId, familyId, scenario, loanApplicationId),
        saveStructure &&
          postStructureForLoanApplicationDirect(
            loanApplicationId,
            structureBuilderForMyCRM(structure),
          ),
      ];
      return Promise.all(promises).then(() => {
        return setClientActivated(clientId, scenario)
          .then(() => {
            return setEmailVerifiedDirect(clientId);
          })
          .then(() => ({
            appId: loanApplicationId,
            familyId,
            clientId,
          }));
      });
    },
  );
};

// port of serverless handler: users/users.setMobile
export const setMobile = (scenario, byobTradingName) => {
  const { mobile, sharedClientId } = scenario;
  if (!mobile || !sharedClientId) {
    return Promise.reject(new Error('mobile or sharedClientId not provided'));
  }

  return putClientMobileDirect(
    sharedClientId,
    buildClientData({ ...scenario, byobTradingName }),
  );
};

// port of serverless handler: users/users.getUserInfo
const notifyClientLogin = (clientId) => {
  return postClientEventDirect(clientId, {
    event_name: 'milli_client_login',
  });
};

const getLoanApplicationForClientId = (
  clientId,
  loanApplicationId,
  isNewLogin,
) => {
  const notifyClientLoginPromise = isNewLogin
    ? notifyClientLogin(clientId)
    : Promise.resolve();
  return notifyClientLoginPromise.then(() => {
    const applicationsPromise = !clientId
      ? Promise.resolve([{ id: loanApplicationId, is_active_app: true }])
      : getLoanApplicationsForClientDirect(clientId);
    return applicationsPromise.then((loanApplications) => {
      const loanApplication = getCorrectApplication(
        loanApplications,
        loanApplicationId,
      );
      if (!loanApplication || !loanApplication.id) {
        return Promise.reject(new Error('There is no application'));
      }
      return getLoanApplication(loanApplication.id, clientId).then(
        (application) => ({
          application: application,
          clientId,
        }),
      );
    });
  });
};

export const getUserInfo = (isNewLogin) => {
  const loanAppIdFromHeader = LocalStorageProxy.loginAsLoanApplicationId;
  const loanApplicationId = parseInt(loanAppIdFromHeader, 10);
  try {
    return authenticate().then(({ user }) => {
      const clientId = user.env_metadata.clientId;
      LocalStorageProxy.currentClientId = clientId;
      if (!clientId && !loanApplicationId) {
        return Promise.reject(
          new Error('clientId or loanApplicationId is required'),
        );
      }

      return getLoanApplicationForClientId(
        clientId,
        loanApplicationId,
        isNewLogin,
      );
    });
  } catch (error) {
    return Promise.reject(error);
    // TODO: We should log out here if the user is a consumer ...?
  }
};

// port of serverless handler: users.sendEmailVerificationToken
export const sendEmailVerificationToken = async (oktaAuth) => {
  await getAccessToken(oktaAuth);

  return postEmailVerificationTokenDirect();
};

// port of serverless handler: users/users.verifyEmailAddress
export const verifyEmailAddress = (token) => {
  return postVerifyEmailDirect({ token }).then((data) =>
    data.Data ? data.Data : data,
  );
};

// port of serverless handler: users/users.recoverPassword
export const recoverPassword = (email, advisorOrgIds) => {
  const postData = {
    email,
    adviserOrgId: advisorOrgIds?.length > 0 ? advisorOrgIds[0] : undefined,
  };

  return postPasswordRecoveryDirect(postData);
};

// port of serverless handler: users.resendOnlineFactFind
export const resendOnlineFactFind = (params) => {
  return postOnlineFactFindDirect(params || {});
};
