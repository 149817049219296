import React from 'react';
import PropTypes from 'prop-types';
import SharedSpinner from '@bit/loanmarket.share.components.spinner';
import SpinningWheel from 'assets/icons/spinning-wheel.svg';
import styles from './Spinner.css';

const Spinner = (props) => {
  const brandColorAsDefault =
    props.variant === 'default' ? 'brandColor__stroke' : '';
  console.log('brandColorAsDefault', brandColorAsDefault);
  return (
    <SharedSpinner
      data-testid='shared.spinner'
      {...props}
      icon={SpinningWheel}
      className={`${styles[props.variant]} ${brandColorAsDefault}`}
    />
  );
};

Spinner.propTypes = {
  variant: PropTypes.oneOf(['inverse', 'default']),
};

export default Spinner;
