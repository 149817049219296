import * as clientActionTypes from 'actions/clientActionTypes';
import * as individualClientActionTypes from 'actions/individualClientActionTypes';
import { actionTypes as loanApplicantActionTypes } from 'actions/loanApplicantActions';
import update from 'immutability-helper';
import { YEARS } from 'shared/constants/options';

import { handleActions } from 'redux-actions';

import {
  clearWorking,
  loadEntityIntoWorking,
  removeEntity,
  setEntity,
  setError,
  setNewEntities,
  setNewEntity,
  setNewOrMergeEntity,
  setWorkingDob,
  setWorkingValue,
  mergeEntity,
} from 'lib/reducerHelper';

const actionTypes = { ...clientActionTypes, ...individualClientActionTypes };
export const newClient = {
  errors: {},
  dob: {},
  mobile: {},
  ageUnit: YEARS,
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newClient,
  },
  titleOptions: [],
};

const clientReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_CLIENT](state, action) {
      return clearWorking(state, action, newClient);
    },
    [actionTypes.LOAD_CLIENT](state, action) {
      return loadEntityIntoWorking(state, action, newClient);
    },
    [actionTypes.LOAD_NEW_FAMILY_MEMBER](state, action) {
      const { contactId, isDependent, role } = action.payload;
      const isCoapplicant = isDependent ? false : action.payload.isCoapplicant;
      return update(state, {
        working: {
          new: {
            $set: update(newClient, {
              $merge: { contactId, isDependent, isCoapplicant, role },
            }),
          },
        },
      });
    },
    [actionTypes.LOAD_NEW_DEPENDENT](state, action) {
      const newDependent = {
        ...newClient,
        isDependent: true,
        id: action.payload,
      };
      return loadEntityIntoWorking(state, action, newDependent);
    },
    [actionTypes.REMOVE_CLIENT](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_CLIENT](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_CLIENTS](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.SET_CLIENT](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_NEW_OR_MERGE_CLIENT](state, action) {
      return setNewOrMergeEntity(state, action);
    },

    [actionTypes.SET_CLIENT_IS_DEPENDENT](state, action) {
      const { id, value: isDependent } = action.payload;
      const isCoapplicant = isDependent ? false : undefined;

      return update(state, {
        working: {
          [id]: {
            $merge: { isDependent, isCoapplicant, primaryApplicant: false },
          },
        },
      });
    },
    [actionTypes.SET_CLIENT_PREFERRED_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'preferredName');
    },
    [actionTypes.SET_CLIENT_PREVIOUS_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'previousName');
    },
    [actionTypes.SET_CLIENT_MOTHERS_MAIDEN_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'mothersMaidenName');
    },
    [actionTypes.SET_CLIENT_IS_CITIZEN_AND_RESIDENT](state, action) {
      return setWorkingValue(state, action.payload, 'isCitizenAndResident');
    },
    [actionTypes.SET_CLIENT_MARITAL_STATUS_ID](state, action) {
      return setWorkingValue(state, action.payload, 'maritalStatusId');
    },
    [actionTypes.SET_CLIENT_TITLE](state, action) {
      return setWorkingValue(state, action.payload, 'title');
    },
    [actionTypes.SET_CLIENT_FIRST_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'firstName');
    },
    [actionTypes.SET_CLIENT_MIDDLE_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'middleName');
    },
    [actionTypes.SET_CLIENT_LAST_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'lastName');
    },
    [actionTypes.SET_CLIENT_MOBILE](state, action) {
      return setWorkingValue(state, action.payload, 'mobile');
    },
    [actionTypes.SET_CLIENT_EMAIL](state, action) {
      return setWorkingValue(state, action.payload, 'email');
    },
    [actionTypes.SET_CLIENT_AGE](state, action) {
      return setWorkingDob(state, action.payload, 'age');
    },
    [actionTypes.SET_CLIENT_AGE_UNIT](state, action) {
      return setWorkingDob(state, action.payload, 'ageUnit');
    },
    [actionTypes.SET_CLIENT_DOB_YEAR](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dob: { year: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_CLIENT_DOB_MONTH](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dob: { month: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_CLIENT_DOB_DAY](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: { dob: { day: { $set: action.payload.value } } },
        },
      });
    },
    [actionTypes.SET_CLIENT_GENDER](state, action) {
      return setWorkingValue(state, action.payload, 'gender');
    },
    [actionTypes.SET_CLIENT_CITIZENSHIP_ID](state, action) {
      return setWorkingValue(state, action.payload, 'citizenshipId');
    },
    [actionTypes.SET_CLIENT_COUNTRY_OF_RESIDENCY_ID](state, action) {
      return setWorkingValue(state, action.payload, 'countryOfResidencyId');
    },
    [actionTypes.SET_CLIENT_IS_RESIDENT](state, action) {
      return setWorkingValue(state, action.payload, 'isResident');
    },
    [actionTypes.SET_CLIENT_RESIDENCY_ID](state, action) {
      return setWorkingValue(state, action.payload, 'residencyId');
    },
    [actionTypes.SET_CLIENT_IS_COAPPLICANT](state, action) {
      return setWorkingValue(state, action.payload, 'isCoapplicant');
    },
    [actionTypes.SET_CLIENT_ROLE](state, action) {
      return setWorkingValue(state, action.payload, 'role');
    },
    [actionTypes.SET_CLIENT_ERROR](state, action) {
      return setError(state, action);
    },
    [actionTypes.SET_TITLE_OPTIONS](state, action) {
      return update(state, {
        titleOptions: {
          $set: action.payload,
        },
      });
    },
    [loanApplicantActionTypes.SET_PRIMARY_APPLICANT](
      state,
      { payload: applicantId },
    ) {
      const payload = state.entities.map((e) => ({
        ...e,
        primaryApplicant: e.id === applicantId,
      }));
      return setNewEntities(state, { payload });
    },
    [actionTypes.SET_CLIENT_STATUS_FROM_APPLICATION](state, action) {
      const {
        currentClientId,
        hasSeenContext,
        hasSignedPrivacyPolicy,
      } = action.payload;
      const currentClient = state.entities.find((e) => {
        return e.id === currentClientId;
      });

      const payload = {
        hasSeenContext: hasSeenContext ?? currentClient.hasSeenContext,
        hasSignedPrivacyPolicy:
          hasSignedPrivacyPolicy ?? currentClient.hasSignedPrivacyPolicy,
        id: currentClientId,
      };

      return mergeEntity(state, {
        payload,
      });
    },
  },
  initialState,
);

export default clientReducer;
